import ApiService from '@/service'


  const state = {
    criteriables:{},
    WatLabPlans:[],
    corporateLogs:[]
  }

  const getters = {
    criteriables(state: any){
      return state.criteriables
    },
    WatLabPlans(state: any){
      return state.WatLabPlans
    },
    corporateLogs(state: any){
      return state.corporateLogs
    },
    surveySteps(state: any){
      return ['suite_survey_step_name_type', 'suite_survey_step_name_viability','suite_survey_step_name_question','suite_survey_step_name_summary']
    },
    watlabSteps(state: any){
      return ["Tipo", "Plan", "Configurar", "Resumen"]
    },
    watFocusSteps(state: any){
      return ["suite_survey_step_name_type","suite_survey_step_name_viability","Usuarios","Sesiones","suite_survey_step_name_summary"]
    }
  }

  const actions = {
    async checkViability(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchCriteria(context: any, payload:any) {
      return ApiService
      .get(`/category/criteriables/${payload.country}`, null, {headers:{"Accept-Language": "es-ES,es;"}})
        .then(({data}: any) => {
          context.commit('setCriteriables',data)
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATSurvey(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watSurvey', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWATSurvey(context: any, payload: any){
      return ApiService
      .put('/clientSuite/watSurvey/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchTestNavigationRules(context: any, payload: any){
      return ApiService
      .get('/test/' + payload.testId + '/navigationRules', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatSurveyViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watSurvey/' + payload.contractId + '/viability ', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatSurvey(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watSurvey/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async fetchWatLabPlans(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watLab/plansData')
      .then(({data}: any) => {
        context.commit('setWatLabPlans', data.reverse())
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async checkWatLabPrice(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watLab/price', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWatLab(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watLab', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWatLabConfiguration(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watLab/'+payload.contractId+'/configuration', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatLabConfiguration(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watlab/' + payload.contractId + '/configurationData')
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatLab(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watLab/' + payload.contractId + '/confirmation')
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async checkViabilityWatUxQuanti(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUxQuanti/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATUxQuanti(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUxQuanti', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchWatUxQuantiViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watUxQuanti/' + payload.contractId + '/viabilityAndPrice', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWATUxQuanti(context: any, payload: any){
      return ApiService
      .put('/clientSuite/watUxQuanti/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatUxQuanti(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watUxQuanti/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async checkViabilityWatUx(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUx/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createWATUx(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watUx', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchWatUxViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watUx/' + payload.contractId + '/viabilityAndPrice', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWATUx(context: any, payload: any){
      return ApiService
      .put('/clientSuite/watUx/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationWatUx(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watUx/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async checkViabilityClickTest(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/clickTest/viabilityAndPrice', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async createClickTest(context: any, payload:any) {
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/clickTest', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    async fetchClickTestViability(context: any, payload: any){
      return ApiService
      .get('/clientSuite/clickTest/' + payload.contractId + '/viabilityAndPrice', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveClickTest(context: any, payload: any){
      return ApiService
      .put('/clientSuite/clickTest/' + payload.contractId + '/step3', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async confirmationClickTest(context: any, payload: any){
      return ApiService
      .post('/clientSuite/clickTest/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async createWatFocus(context: any, payload: any){
      payload.suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post('/clientSuite/watFocus', payload)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatFocusConfiguration(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watFocus/' + payload.contractId + '/configuration', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatFocusUserSlots(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watFocus/' + payload.contractId + '/usersSlots', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async fetchWatFocusVideos(context: any, payload: any){
      return ApiService
      .get('/clientSuite/watFocus/' + payload.contractId + '/videos', null)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response);
      })
    },
    async saveWatFocus(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/configuration', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async confirmationWatFocus(context: any, payload: any){
      return ApiService
      .post('/clientSuite/watFocus/' + payload.contractId + '/confirmation ', payload.data)
      .then(({data}: any) => {
        return data;
      })
      .catch(({response}: any)  => {
        return Promise.reject(response.data);
      })
    },
    async fetchCorporateLogs(context: any, payload: any){
      const suitePlanId = context.getters.clientCreditsPlan.identifier
      return ApiService
      .post(`/clientSuite/corporateLogs/${suitePlanId}/page/${payload.page}/pageSize/${payload.pageSize}`, payload.data)
      .then(({data}: any) => {
        context.commit('setCorporateLogs', data)
        return data;
      })
      .catch(({response}: any) => {
        return response;
      })
    },
  }

  const mutations = {
    setCriteriables(state: any,data: any){
      state.criteriables = data;
    },
    setWatLabPlans(state: any, data: any){
      state.WatLabPlans = data
    },
    setCorporateLogs(state: any, data: any){
      state.corporateLogs = data
    },
  }


export default{
  state,
  getters,
  actions,
  mutations
}
